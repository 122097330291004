import React from 'react'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LogoWhite from "../../assets/images/BitLogotext.png";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AppstoreIcon from '../../assets/images/AppstoreIcon.svg';
import PlaystoreIcon from '../../assets/images/PlaystoreIcon.svg';



export default function Footer() {

    const notify = () => toast("Coming soon");


    return (
        <section className='top-home-footer-section'>
            <ToastContainer />
            <Container maxWidth="lg" className='top-home-footer-section-1'>
                <Grid container
                    spacing={3}>
                    <Grid item xs={7} sm={7} md={3} lg={3}>
                        <Link to="/" className='footer-brand'>
                            <LazyLoadImage
                                src={LogoWhite}
                                alt='store'
                                style={{ width: '70%', height: 'auto', display: 'block', marginBottom: "20px" }}
                            />
                        </Link>
                        <Stack
                            spacing={3}
                            direction="row"
                            useFlexGap
                            sx={{ mt: 4 }}
                        >
                            <a rel="noreferrer" href="" target='_blank'><GitHubIcon /></a>
                            <a rel="noreferrer" href="" target='_blank'><TwitterIcon /></a>
                            <a rel="noreferrer" href="" target='_blank'><FacebookIcon /></a>
                            <a rel="noreferrer" href="" target='_blank'><LinkedInIcon /></a>
                        </Stack>
                    </Grid>
                    <Grid item xs={7} sm={7} md={3} lg={3}>
                        <Stack
                            direction="column"
                            useFlexGap
                            sx={{ flexWrap: 'wrap' }}
                        >
                            <Link to="/about">About</Link>
                            <Link to="/attributions">Attributions</Link>
                            <Link to="/cookie-policy">Cookies</Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={7} sm={7} md={3} lg={3}>
                        <Stack
                            direction="column"
                            useFlexGap
                            sx={{ flexWrap: 'wrap' }}
                        >
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <Link to="/terms">Terms of Use</Link>
                            {/* <Link to="">LicenseAggrement</Link> */}
                        </Stack>
                    </Grid>

                    <Grid item xs={7} sm={7} md={3} lg={3}>
                        <Link onClick={notify}>
                            <LazyLoadImage
                                src={AppstoreIcon}
                                alt='Appstore'
                                style={{ width: '70%', height: 'auto', display: 'block', marginBottom: "20px" }}
                            />
                        </Link>

                        <Link onClick={notify}>
                            <LazyLoadImage
                                src={PlaystoreIcon}
                                alt='Playstore'
                                style={{ width: '70%', height: 'auto', display: 'block' }}
                            />
                        </Link>

                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
