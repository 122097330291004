import { Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
    return (
        <div className='error-page-1'>
            <Container maxWidth="lg" sx={{ pb: 10 }}>
                <h1 className='b-top-text-err-1'>Error 404</h1>
                <div style={{ textAlign: "center" }}>
                    <Link to='/' className='b-top-text-err-btn-1' sx={{ mt: 2 }}>Return Home</Link>
                </div>
            </Container>
        </div>
    )
}

export default Error
