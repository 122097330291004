import React from 'react'
import Grid from '@mui/material/Grid';
import { Button, Container } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Image1 from '../assets/images/Image2.png';
import Image2 from '../assets/images/2.png';
import Image3 from '../assets/images/3.png';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ShieldIcon from '@mui/icons-material/Shield';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RiSecurePaymentFill } from "react-icons/ri";
import { TbMessage2Cog } from 'react-icons/tb';
import { LuMonitorStop } from 'react-icons/lu';
import { SiAdguard } from 'react-icons/si';

function Home() {
  const notify = () => toast("Coming soon");

  return (
    <div>
      <ToastContainer />
      <div className='glow-effect-1'></div>
      <div className='glow-effect-2'></div>


      <section className='top-home-banner-section'>
        <Container maxWidth="lg">
          <Grid container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h1 className='b-top-text-1'>Empower Your Digital Assets — Secure, Simple, and Fast Crypto Wallet</h1>
              <p className='b-top-text-2'>Explore 100+ Blockchains and Thousands of dApps Effortlessly with NV Wallet</p>
              <div style={{ textAlign: "center" }}>
                <Button onClick={notify} className='b-top-text-btn-1' sx={{ mt: 2 }}>Join Wallet</Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Container maxWidth="lg">
        <Grid container
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
          <LazyLoadImage src={Image1} alt='Image1'
            style={{
              width: "100%",
              height: "100%",
            }} />
        </Grid>
      </Container>

      <section className='top-home-banner-section-2'>
        <Container maxWidth="lg">
          <Grid container
            className='top-home-banner-section-2-2'
            spacing={4}
            sx={{
              justifyContent: "center",
            }}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div style={{ textAlign: "center" }}>
                <FingerprintIcon className="b-top-icon-2" />
              </div>
              <Card className="top-home-sec-card-1">
                <CardContent>
                  <h5>Private</h5>
                  <p>Your data stays yours; we never store addresses, balances, or personal information.</p>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div style={{ textAlign: "center" }}>
                <ShieldIcon className="b-top-icon-2" />
              </div>
              <Card className="top-home-sec-card-1">
                <CardContent>
                  <h5>Trust</h5>
                  <p>Fully encrypted and transparent, with no personal data collection.</p>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div style={{ textAlign: "center" }}>
                <DocumentScannerIcon className="b-top-icon-2" />
              </div>
              <Card className="top-home-sec-card-1">
                <CardContent>
                  <h5>Complete Control</h5>
                  <p>With self-custody, you own your assets and have full access to your crypto at all times.</p>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div style={{ textAlign: "center" }}>
                <Diversity3Icon className="b-top-icon-2" />
              </div>
              <Card className="top-home-sec-card-1">
                <CardContent>
                  <h5>Intuitive Interface</h5>
                  <p>Designed for everyone, from beginners to experts, start using crypto in minutes.</p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className='top-home-banner-section-3'>
        <Container maxWidth="lg">
          <Grid container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LazyLoadImage
                src={Image2}
                alt="Image2"
                style={{ width: '75%', height: 'auto', display: 'block' }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h5>Chat & Pay: Secure, Instant, and Convenient</h5>
              <p>The NV Wallet App's special Chat & Pay feature allows users to chat and transact effortlessly within a single platform. Whether you're sending funds to a friend or discussing financial plans, our integrated chat function ensures a seamless and secure experience. Enjoy real-time messaging with end-to-end encryption, allowing you to communicate and transfer funds confidently.</p>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className='top-home-banner-section-4'>
        <Container maxWidth="xl">
          <Grid container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='section-4-card-1'>
                <RiSecurePaymentFill className='section-4-card-icon-1' />
                <h5>Instant Payments</h5>
                <p>Effortlessly send and receive funds directly within the chat, making transactions as simple as sending a message. Whether you're paying a friend, our integrated payment feature ensures that your money moves as fast as your conversation. No need to switch apps or deal with complicated processes—just type, tap, and transact. With instant payment confirmations, you can be sure your funds reach the recipient securely and immediately.</p>
              </div>
              <div className='section-4-card-1' style={{ marginTop: "40px" }}>
                <TbMessage2Cog className='section-4-card-icon-1' />
                <h5>Secure Messaging</h5>
                <p>Communicate with confidence using our secure messaging feature, where all conversations are protected by end-to-end encryption. This advanced security measure ensures that your messages remain private and can only be read by you and the intended recipient, preventing unauthorized access. Whether you’re discussing financial details or general topics, your data is fully safeguarded, providing peace of mind that your personal and financial information is kept confidential and secure at all times.</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <LazyLoadImage
                src={Image3}
                alt="Image3"
                style={{ width: '100%', height: 'auto', display: 'block' }}
              />
            </Grid>


            
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className='section-4-card-1' >
                <SiAdguard className='section-4-card-icon-1' />
                <h5>Smart Notifications</h5>
                <p>Stay informed with real-time alerts for your financial activity. Receive instant notifications whenever payments are made or received, ensuring you’re always up-to-date with your account status. so you never miss your important financial event. Keep your finances on track effortlessly with our smart notification system.</p>
              </div>
              <div className='section-4-card-1' style={{ marginTop: "40px" }}>
                <LuMonitorStop className='section-4-card-icon-1' />
                <h5>User-friendly Experience</h5>
                <p>Our NV wallet app offers an intuitive interface designed for effortless navigation and interaction. You'll find it easy to manage your transactions and communicate with contacts without the distraction of unnecessary features. While we focus on core functionalities, such as payment requests and transaction details, our streamlined design enhances usability and ensures you can quickly access what you need. Enjoy a seamless experience where managing your finances and chatting with friends is simple and efficient, all in one place.</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

    </div>
  )
}

export default Home
