import React from 'react';
import { Container, Typography, Box, Divider, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', // Your local font
    },
});

function CookiePolicy() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Cookie Policy
                </Typography>
                <Divider />
                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Introduction
                    </Typography>
                    <Typography paragraph>
                        This Cookie Policy explains how Bitnevex Wallet ("we," "our," or "us") collects, uses, shares, and stores cookies in relation to its websites. This Policy applies to all Sites, applications, products, and services (collectively referred to as "Services") that are referenced, linked, or provided.
                    </Typography>
                    <Typography paragraph>
                        We are committed to protecting your privacy and being transparent about the technologies we use. This Policy explains when and why we use cookies on our Sites, underscoring our dedication to transparency.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Do We Use Cookies?
                    </Typography>
                    <Typography paragraph>
                        Yes, we, along with our affiliates, marketing partners, and analytics providers, use cookies, web beacons, and pixels to ensure every visitor enjoys an optimal experience on the Sites.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        What is a Cookie?
                    </Typography>
                    <Typography paragraph>
                        A cookie is a small text file placed on your device's hard drive by a web server. It stores information that can be retrieved later by the web server that issued it. Some cookies are only used when specific features or preferences are selected, while others are continuously active. You can review our updated cookie list for details on each cookie, as it changes frequently. Cookies, tags, and scripts can be used on websites and emails to track visits, analyze behavior, measure the success of campaigns, and determine whether an email has been opened or acted upon. Our service and analytics providers may use these technologies to generate individual or aggregate reports.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Why Do We Use Cookies?
                    </Typography>
                    <Typography paragraph>
                        We use cookies for several reasons:
                    </Typography>
                    <ul>
                        <li>To recognize returning or new users</li>
                        <li>To allow user registration and save passwords on our Sites</li>
                        <li>To enhance the functionality of our Sites and understand user interaction</li>
                        <li>To serve targeted, interest-based ads</li>
                        <li>To monitor browsing behavior across various platforms</li>
                        <li>To gain deeper insights into our visitors' interests</li>
                    </ul>
                    <Typography paragraph>
                        Some cookies are essential for the Sites to function properly. These cookies enable us to deliver the services you request, such as verifying account creation and login, storing browsing history, and enhancing the security of our Sites.
                    </Typography>
                    <Typography paragraph>
                        We also use functional and third-party cookies for analysis and marketing purposes. Functional cookies ensure that certain features of the Sites operate correctly, while analytics cookies collect data on how users interact with our Sites and what content they view most frequently. Advertising cookies help us deliver relevant ads and ensure they appear in top search results. Cookies can be classified as "session" cookies, which are deleted when you close your browser, or "persistent" cookies, which remain until manually deleted. Detailed information on the cookies we use can be found in our Cookie Disclosure table.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Disabling Cookies
                    </Typography>
                    <Typography paragraph>
                        You can usually manage cookies through your browser settings. For detailed information on managing cookies in your browser, refer to these links:
                    </Typography>
                    <ul>
                        <li>
                            To learn about "Private Browsing" and manage cookies in Firefox, click <a href="https://support.mozilla.org/en-US/kb/private-browsing-use-firefox-without-history">here</a>.
                        </li>
                        <li>
                            For Incognito mode and cookie management in Chrome, click <a href="https://support.google.com/chrome/answer/95664">here</a>.
                        </li>
                        <li>
                            To learn about InPrivate mode and manage cookies in Internet Explorer, click <a href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer">here</a>.
                        </li>
                        <li>
                            For Private Browsing and managing cookies in Safari, click <a href="https://support.apple.com/en-us/HT201265">here</a>.
                        </li>
                    </ul>
                    <Typography paragraph>
                        For more information on cookies, or to learn how to control, disable, or delete them, visit <a href="http://www.aboutcookies.org">aboutcookies.org</a> Additionally, third-party networks like Google allow users to customize or opt out of certain preferences. To explore these options, <a href="https://adssettings.google.com/authenticated">here</a>.
                    </Typography>
                    <Typography paragraph>
                        Flash cookies may also be used on our Sites. Since they cannot be controlled via browser settings, you can manage Flash cookies by visiting this link. Please note that declining cookies may affect some functionalities on the Sites and limit personalized content and advertisements.
                    </Typography>
                    <Typography paragraph>
                        Cookies may be used to link data from cookies with other information we hold about you. This combined information is used in accordance with our Privacy Policy. Third parties placing cookies on our Sites may also associate your name and email with additional data, including online and offline purchase histories and browsing behavior. If you are located in the European Economic Area, you have specific rights, such as the right to access, correct, or delete your data.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Local Storage Policy
                    </Typography>
                    <Typography paragraph>
                        Local Storage refers to the localStorage feature enabled by JavaScript, which allows websites to store small amounts of data (such as numbers or letters) in your browser. We use Local Storage to assign a unique user ID. Local Storage can be deleted by the website that created it or manually by you through your browser settings.
                    </Typography>
                    <Typography paragraph>
                        <strong>How to Clear Local Storage from Your Browser:</strong>
                    </Typography>
                    <ul>
                        <li>
                            <strong>Firefox:</strong> Local Storage is cleared when you clear recent history, select cookies for deletion, and set the time range to "Everything."
                        </li>
                        <li>
                            <strong>Chrome:</strong> Clear Local Storage by following specific steps within the browser.
                        </li>
                        <li>
                            <strong>Internet Explorer:</strong> To clear Local Storage:
                            <ul>
                                <li>Open Tools and select Internet Options.</li>
                                <li>Under the General tab, enable "Delete browsing history on exit."</li>
                                <li>Select "Cookies & website data" or "temporary web files and website files."</li>
                                <li>Uncheck "Preserve Favorites website data."</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Safari:</strong> To clear Local Storage:
                            <ul>
                                <li>Open Safari and go to Preferences.</li>
                                <li>Navigate to the Privacy tab.</li>
                                <li>Click "Remove all website data."</li>
                                <li>Confirm by clicking "Delete Now."</li>
                            </ul>
                        </li>
                    </ul>

                </Box>

            </Container>
        </ThemeProvider>
    );
}

export default CookiePolicy;
