import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LogoWhite from "../../assets/images/BitLogotext.png";
import { Container } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const navItems = [
    { name: 'App Store', icon: <AppleIcon className='nav-items-top-1-1' /> },
    { name: 'Play Store', icon: <ShopIcon className='nav-items-top-1-1' /> },
];

export default function DrawerAppBar() {

    const notify = () => toast("Coming soon");


    return (
        <Box className="top-new-navbar-css" sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" className={`navbar`} position='relative'>
                <Container maxWidth="lg">
                    <Toolbar>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
                        >
                            <Link to="/" className='navbar-brand'>
                                <LazyLoadImage
                                    alt="Logo"
                                    src={LogoWhite}
                                />
                            </Link>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            {navItems.map((item) => (
                                <Button key={item.name} className='nav-items nav-items-top-1' onClick={notify}>
                                    {item.icon}  {item.name}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

        </Box >
    );
}
