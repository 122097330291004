import React from 'react';
import { Container, Typography, Box, Divider, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', // Your local font
    },
});

function About() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About Us
                </Typography>
                <Divider />

                <Box mt={3}>
                    <Typography paragraph>
                        No matter your level of experience with blockchain, Bitnevex Wallet makes it easy to engage with the decentralized web, often called the next evolution of the internet. Bitnevex is a multi-chain, self-custody cryptocurrency wallet that also serves as a gateway to thousands of Web3 decentralized applications (dApps).
                    </Typography>
                    <Typography paragraph>
                        Trusted by millions worldwide, our goal is to make this new decentralized web accessible to everyone.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography paragraph>
                        Bitnevex Wallet operates as both a wallet and a browser, allowing users to manage their digital assets with ease. You can send and spend your assets, make global payments, and securely log into websites to participate in activities like trading, lending, borrowing, gaming, content creation, purchasing rare digital art, and more.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography paragraph>
                        With Bitnevex Wallet, your keys and assets are always under your control:
                    </Typography>
                    <Typography component="ul">
                        <li>Take advantage of Bitnevex's secure key vault, login features, and digital wallet to manage your assets effectively.</li>
                        <li>Generate passwords and keys directly on your mobile device for enhanced security.</li>
                        <li>Easily browse and connect to decentralized websites.</li>
                        <li>Control the personal information you share with sites, deciding what remains private.</li>
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography paragraph>
                        For beginners, we offer full support to ensure a smooth setup process, including the option to import your existing wallet.
                    </Typography>
                </Box>

            </Container>
        </ThemeProvider>
    );
}

export default About;
