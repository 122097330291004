import React from 'react';
import { Container, Typography, Box, Divider, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', // Your local font
    },
});

function PrivacyPolicy() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <Divider />

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Introduction
                    </Typography>
                    <Typography paragraph>
                        This Privacy Policy outlines the practices of Bitnevex Wallet ("we," "our," or "us") regarding the collection, use, sharing, and protection of personal information collected from users of our services, including our website, mobile applications, and related products and services (collectively, the "Services").
                    </Typography>
                    <Typography paragraph>
                        By using our Services, you agree to the terms of this Privacy Policy and our Terms of Use, and you consent to the collection, use, disclosure, and retention of your information as described herein. If you do not agree with any part of this Privacy Policy or our Terms of Use, please refrain from using our Services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Information Collection
                    </Typography>
                    <Typography paragraph>
                        We collect information about you in various ways, including:
                    </Typography>
                    <Typography paragraph>
                        <strong>Information You Provide Us:</strong> This includes identity details (e.g., name, username), contact information (e.g., email address, phone number), profile data, financial details (e.g., credit card information), transaction history, usage data, marketing preferences, and technical information (e.g., Ethereum wallet address).
                    </Typography>
                    <Typography paragraph>
                        <strong>Information Collected by Infura:</strong> When using Infura as your default RPC provider in Bitnevex Wallet, your IP address and Ethereum wallet address are collected. However, this data is not collected if you connect to Bitnevex using your own Ethereum node or an external RPC provider.
                    </Typography>
                    <Typography paragraph>
                        <strong>Additional Service Information:</strong> This includes identity verification documents, Anti-Money Laundering (AML) and Know Your Customer (KYC) data, source of funds information, and details related to token holdings.
                    </Typography>
                    <Typography paragraph>
                        <strong>Information from Third Parties:</strong> We may receive information about you from third-party sources, which may be combined with data collected through our Services.
                    </Typography>
                    <Typography paragraph>
                        <strong>Automatically Collected Information:</strong> We may collect certain information automatically, such as your IP address, device type, and pages visited, to improve our Services.
                    </Typography>
                    <Typography paragraph>
                        <strong>Cookies:</strong> We use cookies and similar technologies to analyze trends, manage our Sites, track user activity, and gather demographic information. You can control cookie preferences through your browser settings.

                    </Typography>
                </Box>


                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Use of Personal Information
                    </Typography>
                    <Typography paragraph>
                        We use your personal information for the following purposes:
                    </Typography>
                    <ul>
                        <li>Providing and improving our Services</li>
                        <li>Ensuring compliance with laws and regulations</li>
                        <li>Communicating updates, events, and promotions</li>
                        <li>Optimizing our platform and user experience</li>
                        <li>Implementing fraud prevention and safety measures</li>
                        <li>Sharing testimonials or endorsements with your consent</li>
                    </ul>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Sharing of Personal Information
                    </Typography>
                    <Typography paragraph>
                        We do not share your personal information with third parties without your consent, except in the following cases:
                    </Typography>
                    <ul>
                        <li>With our affiliates and subsidiaries</li>
                        <li>In the event of business transfers or transactions</li>
                        <li>To comply with legal obligations or law enforcement requests</li>
                        <li>With professional advisors and service providers</li>
                        <li>With other companies or entities, with your permission</li>
                    </ul>
                    <Typography paragraph>
                        We may share aggregated or anonymized data for analytical purposes.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        International Transfers
                    </Typography>
                    <Typography paragraph>
                        Your personal information may be transferred to or from locations outside of your jurisdiction, including the United States, where privacy laws may differ.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Security of Information
                    </Typography>
                    <Typography paragraph>
                        We follow industry-standard security protocols to protect data shared through our Services. However, we cannot guarantee the security of data transmitted over the Internet.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Your Information Choices
                    </Typography>
                    <Typography paragraph>
                        You can access, update, correct, or delete your information by contacting us at info@Bitnevexapp.io. You may also manage your contact preferences and opt out of certain communications.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Eligibility
                    </Typography>
                    <Typography paragraph>
                        If you are a minor, you may use our Services only with parental or guardian consent.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Marketing Communications and Opt-Out
                    </Typography>
                    <Typography paragraph>
                        You can opt out of marketing communications by following the instructions in our emails or contacting us directly. However, you may continue to receive non-marketing communications related to your account or Services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Third-Party Links
                    </Typography>
                    <Typography paragraph>
                        Our Privacy Policy does not apply to the privacy practices of third-party websites linked from our Sites. We encourage you to review their privacy policies.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Co-Branded Websites
                    </Typography>
                    <Typography paragraph>
                        This Privacy Policy does not cover co-branded websites. We recommend reviewing the privacy notices on each co-branded site you visit.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Changes to This Privacy Policy
                    </Typography>
                    <Typography paragraph>
                        We may update this Privacy Policy periodically. By continuing to use our Services, you accept any changes made to this policy.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Data Protection Officer
                    </Typography>
                    <Typography paragraph>
                        For data protection inquiries or concerns, you can reach our Data Protection Officer at info@Bitnevexapp.io
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography paragraph>
                        For any questions or complaints about this policy, please contact us at info@Bitnevexapp.io.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Notice to EU Data Subjects
                    </Typography>
                    <Typography paragraph>
                        EU data subjects have specific rights under the General Data Protection Regulation (GDPR) regarding their personal information. You may request actions related to your data or file a complaint with your data protection authority or us.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Cross-Border Data Transfers
                    </Typography>
                    <Typography paragraph>
                        Your information may be transferred to the United States or other countries, where we ensure appropriate safeguards are in place to protect your data.
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default PrivacyPolicy;
