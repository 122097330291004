import React from 'react';
import { Container, Typography, Box, Divider, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', // Your local font
    },
});

function Attributions() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    A Crypto Wallet & Gateway to dApps - Attribution Notice
                </Typography>
                <Divider />

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Permission
                    </Typography>
                    <Typography paragraph>
                        Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to use the Software without limitation. This includes the rights to copy, modify, merge, publish, distribute, and sublicense the Software. The following condition applies: All copies or substantial portions of the Software must include the above copyright notice and this permission notice.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Disclaimer
                    </Typography>
                    <Typography paragraph>
                        THE SOFTWARE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. IN NO EVENT SHALL THE COPYRIGHT HOLDERS OR CONTRIBUTORS BE LIABLE FOR ANY CLAIM, DAMAGES, OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT, OR OTHERWISE, ARISING FROM, OUT OF, OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Attributions;
