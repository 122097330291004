import React from 'react';
import { Container, Typography, Box, Divider, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', // Your custom font
    },
});

function TermsofUse() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Terms of Use
                </Typography>
                <Divider />

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Important Notice
                    </Typography>
                    <Typography paragraph>
                        This Agreement includes a mandatory binding arbitration clause and a waiver of CLASS ACTION rights, as described in Section 11. Please read this Agreement thoroughly.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        Introduction
                    </Typography>
                    <Typography paragraph>
                        Bitnevex Wallet ("Bitnevex Wallet," "we," "us," or "our") operates as a decentralized, user-governed software platform. Our software utilizes decentralized technologies, such as Ethereum, to promote innovation in commerce, finance, and business process efficiency. The official website, https://www.Bitnevexapp.io/, contains detailed information about our services and includes subdomains for various product offerings ("the Sites"). These Sites feature a range of content, including text, images, code, and other materials.
                    </Typography>
                    <Typography paragraph>
                        These Terms of Use ("Terms," "Terms of Use," or "Agreement") define the conditions for accessing and using the Site and its Services, creating a binding agreement between you and us or the entity you represent ("you" or "your"). By using the Site or Services, you confirm your acceptance of these Terms. Access to the Site or Services requires your explicit consent, such as by clicking an acceptance button.
                    </Typography>
                    <Typography paragraph>
                        You confirm that you have the legal authority to enter into contracts. If you represent an entity, you also affirm that you are authorized to bind that entity to this Agreement. For definitions of capitalized terms used in this Agreement, please see Section 16.
                    </Typography>
                    <Typography paragraph>
                        Additionally, you represent that neither you, your financial institutions, nor any controlling parties (1) appear on any prohibited or restricted lists, such as those maintained by the U.S. Security Council or U.S. government entities (e.g., the Specially Designated Nationals List, Foreign Sanctions Evaders List, or the Entity List); and (2) are located in a country subject to U.S. embargoes.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        1. Services
                    </Typography>
                    <Typography paragraph>
                        <strong>1.1 Overview:</strong> This Agreement governs your access to and use of the Services. By using the Services, you agree to adhere to this Agreement and all applicable laws, rules, and regulations.
                    </Typography>
                    <Typography paragraph>
                        <strong>1.2 Access and Products:</strong> Bitnevex Wallet offers various products, collectively referred to as "Services," which are accessible via the Site. To use these Services, you may be required to create an account, provide valid payment details, choose a subscription plan ("Plan"), or place an order for a specific service or plan.
                    </Typography>
                    <Typography paragraph>
                        <strong>1.3 Third-Party Content:</strong> Some Services may include content from third-party providers. This Agreement governs the use of such content. Additional terms and fees may apply to specific third-party content.
                    </Typography>
                    <Typography paragraph>
                        <strong>1.4 Third-Party Services:</strong> You may engage third-party services while using our Services, which will be governed by their own terms, policies, and fees.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        2. Changes
                    </Typography>
                    <Typography paragraph>
                        <strong>2.1 Service Modifications:</strong> We may modify, suspend, or discontinue any Service at any time without prior notice
                    </Typography>
                    <Typography paragraph>
                        <strong>2.2 Agreement Changes:</strong> We reserve the right to modify or replace any part of this Agreement, including associated policies. You are responsible for reviewing the Agreement regularly. Continued use of the Services after changes are posted constitutes your acceptance of the revised Agreement.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        3. Your Responsibilities
                    </Typography>
                    <Typography paragraph>
                        <strong>3.1 Account Management:</strong> You are responsible for all activities under your account, regardless of whether these actions are taken by you, employees, or third parties. We are not liable for unauthorized account access.
                    </Typography>
                    <Typography paragraph>
                        <strong>3.2 Legal Compliance:</strong> You must use the Services in accordance with all applicable laws and regulations.
                    </Typography>
                    <Typography paragraph>
                        <strong>3.3 Security and Backup:</strong> You are responsible for configuring and securing your accounts, as well as backing up your data.
                    </Typography>
                    <Typography paragraph>
                        <strong>3.4 Login Credentials:</strong> Login details and API authentication generated by the Services are for internal use only and must not be transferred, sold, or sublicensed, except to agents performing work on your behalf.
                    </Typography>
                </Box>


                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        4. Payment and Fees
                    </Typography>
                    <Typography paragraph>
                        <strong>4.1 Publicly Available Services:</strong> Some Services may be offered to the public on a royalty-free basis.
                    </Typography>
                    <Typography paragraph>
                        <strong>4.2 Service Fees:</strong> Fees will apply at the time of each transaction. Any fee updates will apply unless otherwise stated. You may review and accept or cancel fees prior to completing a transaction.
                    </Typography>
                    <Typography paragraph>
                        <strong>4.3 Taxes</strong> Each party is responsible for paying all applicable taxes, government fees, and levies, including penalties or interest.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        5. Suspension and API Limitations
                    </Typography>
                    <Box mt={3}>
                        <Typography paragraph>
                            <strong> 5.1 Suspension Conditions:</strong> We may temporarily suspend your access to the Services if we determine that your use poses a security risk, could negatively affect our systems or those of others, could expose us to liability, or is considered fraudulent. Suspension may also occur for violations of this Agreement or business-related events like bankruptcy.
                        </Typography>
                    </Box>

                    <Box mt={3}>
                        <Typography paragraph>
                            <strong> 5.2 Consequences of Suspension:</strong> During suspension, you remain responsible for any incurred fees and will not receive Service Credits.
                        </Typography>
                    </Box>

                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        6. Proprietary Rights
                    </Typography>
                    <Typography paragraph>
                        <strong>6.1 Your Content:</strong> You may provide content to us through the Services, but you retain no ownership rights. We may use your content as necessary to provide the Services.
                    </Typography>
                    <Typography paragraph>
                        <strong>6.2 License to Service Offerings:</strong> We own all rights to the Services and associated intellectual property. You are granted a limited, non-exclusive, non-transferable, revocable license to access and use the Services in compliance with this Agreement. Some content may be governed by separate licenses, such as open-source licenses, and in cases of conflict, the terms of the separate license will apply.
                    </Typography>
                    <Typography paragraph>
                        <strong>6.3 License Restrictions:</strong> You and End Users may not modify, distribute, or create derivative works from our content unless expressly permitted. Misrepresentation of our relationship with you is prohibited.
                    </Typography>
                    <Typography paragraph>
                        <strong>6.4 Suggestions:</strong> Any suggestions you provide are transferred to us, and we retain full rights to use them.
                    </Typography>
                </Box>


                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        7. Indemnification
                    </Typography>
                    <Typography paragraph>
                        <strong>7.1 General Indemnification</strong>
                    </Typography>
                    <Typography paragraph>
                        You agree to indemnify us, our affiliates, and licensors from any losses related to (i) breaches of this Agreement or (ii) disputes between you and your customers or users.
                    </Typography>

                    <Typography paragraph>
                        <strong>7.2 Intellectual Property Indemnification</strong>
                    </Typography>
                    <Typography paragraph>
                        We will defend you against third-party claims related to intellectual property violations in connection with your content.
                    </Typography>
                </Box>


                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        8. Disclaimers and Risks
                    </Typography>
                    <Typography paragraph>
                        <strong>8.1 Disclaimer of Liability</strong> The Services are provided "AS IS." We make no warranties regarding the Services or third-party content and disclaim all implied warranties, including merchantability and fitness for a particular purpose.
                    </Typography>
                    <Typography paragraph>
                        <strong>8.2 Risks</strong> The use of our Services involves risks associated with emerging technologies, which you acknowledge and accept.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        9. Limitation of Liability and Exclusions
                    </Typography>
                    <Typography paragraph>
                        <strong>9.1 Limitation of Liability</strong> Neither party is liable for indirect, incidental, or consequential damages, including lost profits, business, or revenue.
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        10. Special Claims Not Recognized
                    </Typography>

                </Box>

                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        11. Binding Arbitration and Class Action Waiver
                    </Typography>
                    <Typography paragraph>
                        Please read this section carefully, as it may significantly impact your legal rights.
                    </Typography>
                    <Typography paragraph>
                        <strong> 11.1 Binding Arbitration</strong>
                    </Typography>
                    <Typography paragraph>
                        For U.S. Residents: Disputes arising from this Agreement are subject to binding arbitration governed by the laws of New York and the Federal Arbitration Act. Arbitration will be conducted per JAMS Comprehensive Arbitration Rules & Procedures. The arbitrator’s decision is final and binding. Any court action related to arbitration will be brought in New York County, New York. Both parties waive the right to a jury trial.
                    </Typography>
                    <Typography paragraph>
                        - For the U.K. Residents: Disputes will be governed by the laws of England and Wales and resolved through binding arbitration as per the LCIA Rules, with London as the arbitration seat.
                    </Typography>
                    <Typography paragraph>
                        - For Other Territories: Disputes will be governed by Irish law. Arbitration will follow the LCIA Rules, with Dublin as the seat. Mediation is required before arbitration.
                    </Typography>
                    <Typography paragraph>
                        <strong> 11.2 Class Action Waiver</strong>
                        Both parties agree to resolve disputes individually and waive any right to participate in a class action. 
                    </Typography>
                    <Typography paragraph>
                        <strong> 11.3 30-Day Right to Opt-Out</strong>
                        You may opt out of the arbitration and class action waiver by sending a written notice to info@Bitnevexapp.io within 30 days of first using the Services.
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default TermsofUse;
